import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const Testimonials = ({ testimonials }) => (
  <div>
    {testimonials.map((testimonial, index) => (
      <article key={testimonial.author} className="message">
        <div className="message-body">
          {testimonial.image ? (
            <div
              style={{
                float: 'left',
                width: '160px',
                display: 'inline-block',
                padding: '8px',
              }}
            >
              <PreviewCompatibleImage imageInfo={testimonial} />
            </div>
          ) : (
            ''
          )}
          {testimonial.quote.split('\n').map((para, index) => (
            <p key={index}>{para}</p>
          ))}
          <br />
          <cite> – {testimonial.author}</cite>
        </div>
      </article>
    ))}
  </div>
)

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

export default Testimonials
