import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Testimonials from '../components/Testimonials'

const TestimonialsPageTemplate = ({ title, testimonials }) => (
  <div className="content">
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="tile">
                  <h1 className="has-text-weight-bold is-size-1">{title}</h1>
                </div>
              </div>
              <Testimonials testimonials={testimonials} />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

TestimonialsPageTemplate.propTypes = {
  title: PropTypes.string,
  testimonials: PropTypes.array,
}

const TestimonialsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <TestimonialsPageTemplate
        title={frontmatter.title}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  )
}

TestimonialsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default TestimonialsPage

export const testimonialsPageQuery = graphql`
  query TestimonialsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        testimonials {
          author
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 160, quality: 64, layout: CONSTRAINED)
            }
          }
          quote
        }
      }
    }
  }
`
